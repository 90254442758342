import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { verifyPrereqCertificate } from "../../../api/prereqApi";
import { preReqStepAction } from "../../../redux/actions/preReqStepAction";
import { updateUserPreReqAction } from "../../../redux/actions/userActions";
import { prereqStatusLookup } from "../../../utils/helpers/data";

import { FileUpload } from "@storybook/stories/FileUpload/FileUpload";
import { preReqSuccess } from "./PreReqSuccess";

const PreReqCompStep1 = () => {
  const token = useSelector(state => state.token.token);
  const [certificate, setCertificate] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const preReqSuccess2Days = {
    ...preReqSuccess,
    title: "Thank you for your submission!\nYou will hear from us in 2 business days."
  }

  const submitPrereq = async () => {
    setError(null);
    setLoading(true);
    const response = await verifyPrereqCertificate([certificate], token);
    setLoading(false);

    if (!response.success) {
      setError(response.message);
    } else {
      dispatch(updateUserPreReqAction(prereqStatusLookup.PENDING));
      dispatch(preReqStepAction(preReqSuccess2Days));
    }
  }

  const attachFile = useCallback((file) => {
    setCertificate(file);
  }, [certificate]);

  return (
    <>
      <FileUpload
        title={"Great! \nPlease upload your CompTIA Certificate here to submit for review."}
        submitFile={ () => submitPrereq() }
        getFile={ (file) => attachFile(file) }
        fileSize={5}
        name={'comptia'}
        btnLoading={loading}
        allowedExt="pdf,docx,jpg,jpeg,png,odt,rtf"
        error={error}
      />
    </>
  );
}

export const preReqCompStep1 = {
  icon: null,
  title: null,
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-left',
  children: <PreReqCompStep1 />,
};