/**
 * Handles API responses
 * @param {*} response
 */
export async function handleResponse(response) {
  if (response.status === 200) {
    return response.data;
  }
  const error = await response.statusText;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    throw new Error("Server error: ", error);
  }

  throw new Error("Network response was not ok.", error);
}

/**
 * Handle errors that occur during API calls.
 * @param {*} error
 */
export function handleError(error) {
  // handle error in ErrorPage
  return { error };
}
