import React from "react";
import { learnerStatusLookup } from "../utils/helpers/data";
import isWithinDateRange from "../utils/isWithinDateRange";

const useTimelyCareBroadcast = (learnerStatus) => {
  if (learnerStatus !== learnerStatusLookup["STARTED_PROGRAM_PATHWAY"])
    return null;

  const dateToTest = new Date();
  const startDate = new Date("8/12/2024"); // Aug 12 2024
  const endDate = new Date("8/30/2024"); // Aug 30 2024

  const isNowInRange = isWithinDateRange(dateToTest, startDate, endDate);

  if (isNowInRange) {
    return (
      <div className="banner-experience-survey">
        <p>
          Calbright’s wellness services provider will transition from Virtual
          Care Group (VCG) to TimelyCare on August 30th.
          <br /> Please check your email for details.
        </p>
      </div>
    );
  }
  return null;
};

export default useTimelyCareBroadcast;
