import React from "react";
import PropTypes from "prop-types";

import useCyberSecurityBroadcast from "../../hooks/useCyberSecurityBroadcast";
import useTimelyCareBroadcast from "../../hooks/useTimelyCareBroadcast";
import useDataAnalysisBroadcast from "../../hooks/useDataAnalysisBroadcast";
import {
  learnerStatusLookup,
  studentProgramLookup,
} from "../../utils/helpers/data";

import "./broadcast-message.css";

const BroadcastMessage = ({ learnerStatus, currentProgram }) => {
  const cyberSecurityBroadcast = useCyberSecurityBroadcast(
    currentProgram,
    learnerStatus
  );
  const dataAnalysisBroadcast = useDataAnalysisBroadcast(
    currentProgram,
    learnerStatus
  );
  const timelyCareBroadcast = useTimelyCareBroadcast(learnerStatus);

  return (
    <>
      {cyberSecurityBroadcast}
      {timelyCareBroadcast}
      {dataAnalysisBroadcast}
    </>
  );
};

export default BroadcastMessage;

BroadcastMessage.propTypes = {
  learnerStatus: PropTypes.oneOf(
    Object.entries(learnerStatusLookup).map(([key, value]) => value)
  ),
  currentProgram: PropTypes.oneOf(
    Object.entries(studentProgramLookup).map(([key, value]) => value)
  ),
};
