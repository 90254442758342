/**
 * Student Onboarding tasks displayed in My Tasks section of dashboard
 */
export const studentOnboarding = [
  // {
  //   id: "prerequisite-verification",
  //   title: "Pre-requisite Check",
  //   subtitle: "",
  //   description: "Lorem ipsum dolores....",
  //   progress: "passed",
  //   handleClick: () => console.log('Will open pre-requisite modal form')
  // },
  {
    id: "schedule-onboarding",
    title: "Schedule Onboarding",
    subtitle: "",
    description:
      "Welcome to Calbright! Your first important task is to sign up for and attend a virtual Onboarding session. During Onboarding, you’ll meet fellow students and the enrollment team, and you’ll find out how to be successful at Calbright. Most importantly, you’ll officially enroll in your program.",
    progress: "in-progress",
    statusLabel: "Pending Review",
    url: "#",
    linkUrl: "/appointment?type=schedule-onboarding",
  },
  {
    id: "enrollment-agreement",
    title: "Sign Enrollment Agreement",
    subtitle: "",
    statusLabel: "Signed during onboarding",
    description:
      "During your Onboarding session, you’ll officially enroll in your program of study by filling out and signing your enrollment agreement. This task will get checked off automatically as long as you complete it during Onboarding (if it doesn't, please wait upto 5 mins after signing your Enrollment Agreement, then refresh your browser to view updates).",
    progress: "default",
  },
  {
    id: "schedule-kickoff",
    title: "Schedule Kickoff",
    subtitle: "",
    description:
      "The second part of orientation at Calbright is the Program Kickoff. This session is led by your instructor who’ll provide more details about your academic program and how to navigate the learning platform where you’ll complete your work. Make sure to sign up for and attend the kickoff to ensure a strong academic start.",
    progress: "default",
    linkUrl: "/appointment?type=schedule-kickoff",
  },
  {
    id: "complete-assignment",
    title: "Complete First Assignment",
    subtitle: "",
    description:
      "To become an actively enrolled student at Calbright, you’ll need to complete your first assignment within 30 days of your Onboarding session. During your Program Kickoff, the instructor will show you how to complete your first assignment. If you need more help with the assignment, contact Peer Tutoring using the link below.",
    progress: "default",
    linkUrl: "https://www.calbright.edu/strut",
  },
];

/**
 * Student tasks displayed under My Tasks on the dashboard
 */
export const Milestone1 = {
  title: "Milestone 1",
  completionDate: "07/15/2023",
  courses: [
    {
      title: "1st Assignment",
      subtitle: "Complete",
      progress: "assignment-1",
      icon: "assignment-1",
    },
    {
      title: "BUS500",
      subtitle: "Machine Learning",
      progress: "started",
    },
    {
      title: "Milestone 1",
      subtitle: "In Progress",
      progress: "default",
      icon: "milestone-1",
    },
  ],
};

export const Milestone1b = {
  title: "Milestone 1",
  completionDate: "07/15/2023",
  courses: [
    {
      title: "1st Assignment",
      subtitle: "Complete",
      progress: "assignment-1",
      icon: "assignment-1",
    },
    {
      title: "BUS500",
      subtitle: "Machine Learning",
      progress: "in-progress",
    },
    {
      title: "Milestone 1",
      subtitle: "In Progress",
      progress: "in-progress",
      icon: "milestone-1-inprogress",
    },
  ],
};

export const Milestone1c = {
  title: "Milestone 1",
  completionDate: "07/15/2023",
  courses: [
    {
      title: "1st Assignment",
      subtitle: "Complete",
      progress: "assignment-1",
      icon: "assignment-1",
    },
    {
      title: "BUS500",
      subtitle: "Machine Learning",
      progress: "done",
    },
    {
      title: "Milestone 1",
      subtitle: "In Progress",
      progress: "assignment-1",
      icon: "milestone-1-complete",
    },
  ],
};

export const Milestone2 = {
  title: "Milestone 2",
  completionDate: "07/15/2023",
  courses: [
    {
      title: "Milestone 1 Complete",
      subtitle: "",
      progress: "milestone-1-complete",
    },
    {
      title: "BUS500",
      subtitle: "Algorithms",
      progress: "started",
    },
    {
      title: "BUS500",
      subtitle: "Algorithms",
      progress: "default",
    },
    {
      title: "BUS500",
      subtitle: "Algorithms",
      progress: "default",
    },
  ],
};

export const studentPathway = [
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "started",
  },
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "default",
  },
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "default",
  },
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "default",
  },
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "default",
  },
  {
    title: "BUS500",
    subtitle: "Algorithms",
    progress: "default",
  },
];

export const progressStages = [
  { progress: 0, step: 1, status: ["started"] },
  { progress: 1, step: 1, status: ["in-progress"] },
  { progress: 15, step: 2, status: ["passed", "started"] },
  { progress: 20, step: 2, status: ["passed", "in-progress"] },
  { progress: 38, step: 3, status: ["passed", "passed", "in-progress"] },
  { progress: 50, step: 3, status: ["passed", "passed", "started"] },
  { progress: 63, step: 4, status: ["passed", "passed", "passed", "started"] },
  { progress: 85, step: 4, status: ["passed", "passed", "passed", "started"] },
  { progress: 88, step: 5, status: ["passed", "passed", "passed", "passed", "started"] },
  { progress: 100, step: 5, status: ["passed", "passed", "passed", "passed", "assignment-1"] }
];
