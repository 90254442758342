import React from "react";

import AccessibilityAbout from "../../assets/accessibility-services/accessibility-about.svg";
import AccessibilityRegister from "../../assets/accessibility-services/accessibility-register.svg";
import AccessibilityFAQ from "../../assets/accessibility-services/accessibility-faq.svg";
import AccessibilityStudentRights from "../../assets/accessibility-services/accessibility-student-rights.svg";
import AccessibilityStudentResources from "../../assets/accessibility-services/accessibility-student-resources.svg";

import MeetOurTeam from "../../assets/accessibility-services/meet-our-team.svg";

import { Accordion } from "@storybook/stories/Accordion/Accordion";

const AccessibilityFAQData = [
  {
    title:
      "Am I automatically eligible for accessibility services or do I have to apply?",
    content:
      "Accessibility services are voluntary. You have the choice to apply for and request services; they are not automatically provided.",
  },
  {
    title: "When is the best time to apply for services?",
    content: (
      <>
        As soon as you enroll in a program at Calbright. However, if you require
        reasonable accommodations to participate in the Calbright enrollment
        process, please submit this{" "}
        <a
          href="https://docs.google.com/forms/d/1VXv72oZQWxkhXyS48hA_m4LTeEKoPbCO89R8bWz0--s/edit"
          target="_blank"
        >
          Reasonable Accommodation Request Form
        </a>
        . We’ll coordinate necessary accommodations to facilitate your
        enrollment in a program pathway. After enrollment, you’ll need to
        register for services by submitting the Accessibility Services{" "}
        <a href="https://forms.gle/pgWKTWGyvDW5jSk78" target="_blank">
          Intake Form
        </a>
        , and providing disability verification documentation to request
        continued support and services.
      </>
    ),
  },
  {
    title: "Are services confidential?",
    content:
      "All disability-related information, including documentation, accommodation letters, correspondence and consultations are considered confidential and managed in accordance with The Family Educational Rights and Privacy Act (FERPA) regulations for the purpose of administering services. If you'd like us to share information with other programs, we require your written consent. Some of the information you provide may be collected for institutional research and reporting purposes to the Chancellor’s office. We also have to abide by legal requirements of disclosure.",
  },
  {
    title:
      "What if I am not sure if my disability/medical condition would qualify me for services?",
    content:
      "Talk to us about it, and we can help you determine if you qualify for services. Don’t rule yourself out. Let us advise you. Even if you don’t qualify, we may know of support and resources that can help you with your academic needs.",
  },
  {
    title:
      "Calbright’s competency-based/asynchronous model may address some of my disability related needs. Should I still apply?",
    content:
      "Yes. Accessibility Services cover a  wide range of support, services, referrals and advocacy that could address your needs at and beyond Calbright.",
  },
  {
    title:
      "Is there a form that my medical/disability services provider can provide to serve as disability verification?",
    content: (
      <>
        ​​If you don't have disability documentation but are working with a
        provider who could provide verification, ask them to complete this{" "}
        <a
          href="https://drive.google.com/file/d/1-aW4XmCS8OLxIEb1dKeN-MaZmYThKyQy/view?usp=sharing"
          target="_blank"
        >
          Accessibility Services Verification Form
        </a>{" "}
        for you to{" "}
        <a href="mailto:accessibilityservices@calbright.org">submit to us</a>.
      </>
    ),
  },
  {
    title:
      "How do I request accommodations for my industry certification exams?",
    content:
      "Requesting accommodations for your industry certification exam from the third-party provider that administers the exam is your responsibility. Calbright can only offer guidance and information regarding this and is not responsible for arranging the accommodations.",
  },
];

export const AccessibilityServicesData = [
  {
    id: 1,
    title: "About Accessibility Services",
    description:
      "Accessibility Services aims to ensure that registered students with disabilities can participate fully and equitably access all educational and programmatic opportunities in pursuit of their educational goals.  Accessibility Services fosters student confidence, self-advocacy and independence to prepare students for success at Calbright and beyond.",
    image: AccessibilityAbout,
    content: (
      <div className="storybook-learning-content">
        <h3>Services Offered</h3>
        <p>
          Accessibility Services offers registered students with disabilities
          individualized services based on the student’s unique
          disability-related needs, functional and educational limitations, and
          academic goals. These services are specific to the student and include
          specialized counseling, individualized academic accommodations,
          technical support, referrals to resources, and advocacy support, with
          the goal of fostering equitable access and academic success. Examples
          of common academic accommodations and supports may include, but are
          not limited to:
        </p>
        <ul>
          <li>Extended testing time on timed tests</li>
          <li>Assistive technology and training (e.g., Kurzweil 3000)</li>
          <li>Alternate media</li>
          <li>ASL interpreting</li>
          <li>Real-time closed captioning</li>
          <li>Auxiliary aids</li>
          <li>Advocacy support</li>
          <li>College- and community-based referrals</li>
        </ul>
        <h3>Office Hours</h3>
        <p>Monday-Friday 9 a.m. to 6 p.m.</p>
        <h3>Contact Information</h3>
        <p>
          Email:{" "}
          <a href="mailto:accessibilityservices@calbright.org">
            accessibilityservices@calbright.org
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Register with Accessibility Services",
    description:
      "We encourage all students with disabilities to register with Accessibility Services. Registering ensures that you are set up with necessary reasonable accommodations and benefit from individualized support, services and advocacy offered by the program.",
    image: AccessibilityRegister,
    content: (
      <div className="storybook-learning-content">
        <p className="mb-20">Here's how to register:</p>
        <p className="mb-20">
          <strong>
            Step 1: Complete the{" "}
            <a href="https://forms.gle/pgWKTWGyvDW5jSk78" target="_blank">
              Accessibility Services Intake Form
            </a>
            .
          </strong>
          The application takes about 5 minutes.
        </p>
        <p className="mb-20">
          <strong>
            Step 2: Provide disability verification for eligibility.{" "}
          </strong>
          If your disability is verifiable by direct observation, disability
          documentation may not be required. .
        </p>
        <p className="mb-20">
          <strong>
            Step 3: Sign up for an{" "}
            <a
              href="https://calendly.com/calbright-accessibility-services/intake-appointment"
              target="_blank"
            >
              intake appointment
            </a>
            .{" "}
          </strong>
          After submitting your application and disability verification,
          schedule an intake appointment. You’ll meet your accessibility
          services counselor to develop an individualized academic accommodation
          plan together.
        </p>
        <p>
          Please don’t hesitate to email us at
          <a href="mailto:accessibilityservices@calbright.org" target="_blank">
            accessibilityservices@calbright.org
          </a>{" "}
          to ask for help or to learn more about our services.
        </p>
        <h3>Disability verification examples</h3>
        <p className="mb-20">
          Disability verification documentation includes, but is not limited to
          the following:
        </p>
        <ul>
          <li>IEP/504 plan/school psychologist's assessment</li>
          <li>Letter from doctor or therapist</li>
          <li>Printout from your insurance website indicating diagnosis</li>
          <li>SSI/SSDI paperwork with disability information</li>
          <li>
            Disability documents from the CA Department of Rehabilitation (DOR)
          </li>
          <li>
            An{" "}
            <a
              href="https://drive.google.com/file/d/1-aW4XmCS8OLxIEb1dKeN-MaZmYThKyQy/view"
              target="_blank"
            >
              Accessibility Services Verification Documentation form
            </a>{" "}
            completed and signed by your medical provider and emailed to us
          </li>
          <li>
            If your disability is verifiable by direct observation, disability
            documentation may not be required.
          </li>
        </ul>
        <h3>Why is disability verification important?</h3>
        <ol>
          <li>
            It helps Calbright qualify and serve students with disabilities.
          </li>
          <li>
            It assists the Accessibility Success counselor in developing a
            comprehensive understanding of the student’s disability-related
            limitations and requirements in relation to their academic needs.
          </li>
          <li>
            It helps the Accessibility Success counselor provide a more personal
            and relevant academic accommodation plan that ensures equitable
            access and suitable referrals.
          </li>
        </ol>
        <br />
      </div>
    ),
  },
  {
    id: 3,
    title: "Frequently Asked Questions",
    description: (
      <>
        Please review these FAQs that may answer some of the questions you still
        have. If you need further assistance, please email us at{" "}
        <a href="mailto:accessibilityservices@calbright.org">
          accessibilityservices@calbright.org
        </a>
        . We are happy to help.
      </>
    ),
    image: AccessibilityFAQ,
    content: (
      <div className="storybook-learning-content">
        <Accordion items={AccessibilityFAQData} />
      </div>
    ),
  },
  {
    id: 4,
    title: "Student Rights and Responsibilities",
    description: "",
    image: AccessibilityStudentRights,
    content: (
      <div className="storybook-learning-content">
        <ol className="pl-15 mt-0">
          <li className="pl-10 pb-15">
            To qualify for accessibility services, a student must apply for
            services and provide information, documentation and/or forms
            (medical, educational, etc.) deemed necessary to verify a
            disability.
          </li>
          <li className="pl-10 pb-15">
            Applying for accessibility services is entirely voluntary and is the
            student’s responsibility to identify as an individual with a
            disability, to request accommodations and/or support services, and
            to seek information, counsel, or assistance as desired.
          </li>
          <li className="pl-10 pb-15">
            Like all Calbright students, students with disabilities must comply
            with the Student Code of Conduct adopted by Calbright College.
          </li>
          <li className="pl-10 pb-15">
            A student using Calbright’s accessibility services must use services
            responsibly and adhere to written procedures and responsibilities.
          </li>
          <li className="pl-10 pb-15">
            The failure of a student with a disability to use services
            responsibly, or to adhere to written procedures and responsibilities
            pertaining to a service, will initiate a written notification from
            Accessibility Services of possible suspension of services. The
            student will be invited to discuss the issue in a meeting with the
            Equity Services Manager. Written notice will be sent to the student
            before any action is taken to suspend services.
          </li>
        </ol>
        <p>
          Following is additional information from the Office of Civil Rights
          regarding rights and responsibilities of students with disabilities:{" "}
          <a
            href="http://www2.ed.gov/about/offices/list/ocr/transition.html"
            target="_blank"
          >
            Students with Disabilities Preparing for Postsecondary Education:
            Know Your Rights and Responsibilities
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "Career and Resources",
    description:
      "Please check these resources that provide important information regarding requesting accommodations, legal references and employment",
    image: AccessibilityStudentResources,
    content: (
      <div className="storybook-learning-content">
        <h3>Career and Employment Resources</h3>
        <ul>
          <li>
            <a href="https://www.dor.ca.gov/Home/WhatWeDo" target="_blank">
              California Department of Rehabilitation (DOR)
            </a>
          </li>
          <li>
            <a
              href="https://www.dor.ca.gov/Home/BlindFieldServices"
              target="_blank"
            >
              DOR: Blind Field Services
            </a>
          </li>
          <li>
            <a href="https://www.nad.org/" target="_blank">
              National Federation of the Deaf
            </a>
          </li>
          <li>
            <a href="https://askjan.org/" target="_blank">
              Job Accommodation Network
            </a>
          </li>
          <li>
            <a href="https://www.wrp.gov/wrp?id=wrp_about_us" target="_blank">
              Federal recruitment of students with disabilities
            </a>
          </li>
        </ul>
        <h3>Requesting Accommodations for Industry Certification Exams</h3>
        <p className="pl-20">
          Students who require accommodation for any exam, course or process
          that is managed or delivered by an organization other than Calbright
          (a “third-party”) must work directly with the exam provider to request
          and arrange for any needed accommodation. It is the student’s
          responsibility to request accommodations directly from them. The
          following resources provide information on requesting accommodations
          for Calbright program:{" "}
        </p>
        <ul>
          <li>
            <strong>Data Analysis:</strong> You must{" "}
            <a
              href="https://www.coursera.support/s/article/360036160591-How-to-contact-Coursera?language=en_US"
              target="_blank"
            >
              contact Coursera
            </a>{" "}
            directly for accomodations.
          </li>
          <li>
            <strong>CRM:</strong>{" "}
            <a
              href="https://trailhead.salesforce.com/help?article=Requesting-Accommodations-for-Proctored-Exams"
              target="_blank"
            >
              Salesforce Trailhead request accomodations
            </a>
          </li>
          <li>
            <strong>IT Support/Networks/Cybersecurity:</strong>{" "}
            <a
              href="https://home.pearsonvue.com/Test-takers/Accommodations.aspx"
              target="_blank"
            >
              visit Pearson VUE test-takers accommodations
            </a>
          </li>
        </ul>
        <h3>Legal References</h3>
        <ol>
          <li className="pl-10">
            <a
              href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/statutes/section-504-rehabilitation-act-of-1973"
              target="_blank"
            >
              Section 504 of the Rehabilitation act of 1973
            </a>
          </li>
          <li className="pl-10">
            <a href="https://www.ada.gov/2010_regs.htm" target="_blank">
              The Americans with Disabilities Act Amendments Act (ADAAA)
            </a>
          </li>

          <li className="pl-10">
            <a href="https://www.ada.gov/law-and-regs/ada/" target="_blank">
              Family Educational Rights and Privacy Act (FERPA)
            </a>
          </li>
        </ol>
        <br />
      </div>
    ),
  },
];

export const MeetYourTeamData = [
  {
    id: 1,
    name: "Meet our Team Members",
    role: (
      <>
        <strong>Program Contact Information</strong>
      </>
    ),
    department: "Technology and Online Learning",
    phone: "",
    slack: "",
    email: "accessibilityservices@calbright.org",
    photo_url: MeetOurTeam,
  },
  {
    id: 2,
    name: "Carlos Cid",
    role: "Student Support Specialist",
    department: "",
    phone: "",
    slack: "",
    email: "carlos.cid@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/e00262a1-8e61-4a03-bc5f-36e18a79bb3f.png",
  },
  {
    id: 3,
    name: "Errin Sullivan Arcos",
    role: "Success Counselor",
    department: "Accessibility Services",
    phone: "",
    slack: "",
    email: "Errin.SullivanArcos@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/2653e14d-c7a4-42c8-a2b6-742ef5be3109.png",
  },
  {
    id: 4,
    name: "Himani Tiwari",
    role: "Manager of Equity Services",
    department: "Accessibility and Veteran Programs",
    slack: "",
    email: "himani.tiwari@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/ecdafdf0-460b-429e-95eb-dabd63e4e9d7.png",
  },
];
