import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { preReqStepAction } from "../../../redux/actions/preReqStepAction";
import {
  verifyPrereqCertificate,
  PROF_EXPERIENCE_FILE_NAME
} from "../../../api/prereqApi";

import { updateUserPreReqAction } from "../../../redux/actions/userActions";
import { prereqStatusLookup } from "../../../utils/helpers/data";

import { MultiFileUpload } from "@storybook/stories/MultiFileUpload/MultiFileUpload";
import { preReqSuccess } from "./PreReqSuccess";

const PreReqMoreStep2 = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.token.token);
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [profExpFile, setProfExpFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const profExp = localStorage.getItem('professionalExperience');

    if (profExp.length < 1) return;

    const newFile = new File([profExp], PROF_EXPERIENCE_FILE_NAME, {
      type: "text/plain",
    });

    setProfExpFile(newFile);
  }, []);

  const submitPrereq = async () => {
    setError(null);
    setLoading(true);
    const response = await verifyPrereqCertificate([...uploadedFiles, profExpFile], token);
    setLoading(false);

    if (!response.success) {
      setError(response.message);
    } else {
      localStorage.removeItem("professionalExperience");
      dispatch(updateUserPreReqAction(prereqStatusLookup.PENDING));
      dispatch(preReqStepAction(preReqSuccess));
    }
  }

  return (
    <>
      <MultiFileUpload
        title="Please attach supporting documentation - such as resume, certificates, transcripts, or other documents for verifying 1 year of prior IT experience."
        submitValue={ () => submitPrereq() }
        getFiles={ (files) => setUploadedFiles(files) }
        fileSize={5}
        btnLoading={loading}
        allowedExt="pdf,docx,jpg,jpeg,png,odt,rtf"
        error={error}
        subtitle="At least 1 document attach required, each document file size limited: 5MB"
      />
    </>
  );
}

export const preReqMoreStep2 = {
  icon: null,
  title: null,
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-left',
  children: <PreReqMoreStep2 />,
};