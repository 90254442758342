import AvatarFemale from "../../assets/avatars/fem-green.svg";

/**
 * Mock user for testing.
 */

export const mockUser = {
  firstName: "Mariah",
  lastName: "Villalobos",
  name: "Mariah Villalobos",
  email: "mariah.villalobos@calbright.org",
  cccId: "CALBRDEMO01", //"E397458",
  hasPhoto: true,
  photoUrl: AvatarFemale,
  enrollmentDate: "2023-01-07T20:00:00.000+0000",
  termEndDate: "12/31/2023",
  learningProgress: "schedule-onboarding",
  learnerStatus: "Started Program Pathway",
  enrolled: true,
};

export const mockUserDashboardData = {
  cccId: "CALBRDEMO01",
  firstName: "Mariah",
  lastName: "Villalobos",
  email: "Mariah.Villalobos@calbright.org",
  courses: [
    {
      code: "IT500",
      completed: false,
      name: "Introduction to Information Technology Support (A+)",
      progress: 3,
    },
    {
      code: "WF500",
      completed: false,
      name: "College and Career Essential Skills",
      progress: 0,
    },
  ],
  currentProgram: "IT Support",
  currentTermEndDate: "2024-03-01",
  enrollmentDate: "2023-12-18T20:00:00.000+0000",
  learnerStatus: "Started Program Pathway",
  enrolled: true,
  lms: "Canvas",
  phoneNumber: "4254203360",
  profileImageUrl:
    // "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/students/thumbnails/User_Avatar_Default.png",
    "https://static.calbrightcollege.org/assets/student-avatars/user_1.svg",
  programOfInterest: "IT Support;Cybersecurity",
  programVersion: "IT500 - v5.0, WF500 - v5.0",
  salesforceId: "0037f00000rMkYWAA0",
  address: "123 Main Street Sacramento, CA, 95213",
  dropDeadline: "2023-08-17",
  withdrawDeadline: "2023-10-24",
  prereqStatus: null, // null, approved, pending, denied, waiting_support,
  prereqCertSubmissionAllowed: true,
  prereqDeniedReason: "Transcript Issue",
  prereqDeniedResponse: "Your transcript submitted was insufficient....",
  prereqExpProofSubmissionAllowed: true,
  prereqLastAnswerExpProof: null,
  prerequisiteAttempts: 1
};

// Onboard - Demo data
export const userProgress = {
  title: "My Tasks",
  progress: 0,
  section: "onboarding",
  program: "Data Analysis",
  date: "",
  milestone: {
    name: "Onboarding",
    progress: 0,
    type: "onboarding",
  },
  courses: [],
};

// Default apps
export const appsEnabled = ["slack", "gmail", "googleDrive", "googleCalendar"];
export const helpIconsEnabled = ["IconCU"];

// Sample Dashboard apiData
export const apiData = {
  first_name: "Raghav",
  last_name: "Naswa",
  email: "raghav.naswa@calbright.org",
  phone_number: "4254203360",
  profile_image_url:
    "https://drive.google.com/uc?id=11UBid6jQMfIZpkj61ifv0oUvJCR7x1og",
  current_program: "IT Support",
  enrollment_date: "2023-12-18T20:00:00.000+0000",
  current_term_end_date: "2024-03-01",
  learner_status: "Started Program Pathway",
  enrolled: false,
  theme: "theme-cold",
  courses: [
    {
      code: "IT500",
      completed: true,
      name: "Introduction to Information Technology Support (A+)",
      progress: 100,
    },
    {
      code: "WF500",
      completed: false,
      name: "College and Career Essential Skills",
      progress: 30,
    },
  ],
  lms: "Canvas",
  program_of_interest: "IT Support;Cybersecurity",
  program_version: "IT500 - v5.0, WF500 - v5.0",
  salesforce_id: "0037f00000rMkYWAA0",
};

export const mockDashboardAPIError = {
  error:
    'Failed to fetch user with email Mariah.Villalobos@calbright.org from Salesforce: RequestFailed (status 401): [{"message":"Session expired or invalid","errorCode":"INVALID_SESSION_ID"}]',
};

/* Active Coursework - Demo data
  // const mockUser = {
  //   title: "Active Coursework",
  //   progress: 0,
  //   section: "no-milestone",
  //   program: "Data Analysis",
  //   date: "",
  //   milestone: {},
  //   courses: [
  //     {
  //       courseId: 'UUID', 
  //       programName: 'Data Analysis',
  //       courseName: 'Introduction to Structured Data',
  //       deptNum: '500',
  //       deptName: 'BUS',
  //       courseVersion: 1, 
  //       completionDate: '2021-01-01', 
  //       intendedCompletionDate: '2021-01-01',  
  //       progress: 25
  //     },
  //     {
  //       courseId: 'UUID', 
  //       programName: 'Data Analysis',
  //       courseName: 'Application of Structured Data - Noncredit',
  //       deptNum: '501',
  //       deptName: 'BUS',
  //       courseVersion: 1, 
  //       completionDate: '2021-01-01',
  //       intendedCompletionDate: '2021-01-01', 
  //       progress: 50
  //     }
  //   ]
  // };
  // const appsEnabled = appIcons.map(iconObject => iconObject.key);
  // const helpIconsEnabled = helpIcons.map(iconObject => iconObject.name);
  */
