import React from "react";
import {
  learnerStatusLookup,
  studentProgramLookup,
} from "../utils/helpers/data";
import isWithinDateRange from "../utils/isWithinDateRange";

const useDataAnalysisBroadcast = (currentProgram, learnerStatus) => {
  if (currentProgram !== studentProgramLookup["DATA_ANALYSIS"]) {
    return null;
  }

  if (
    learnerStatus !== learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] &&
    learnerStatus !== learnerStatusLookup["STARTED_PROGRAM_PATHWAY"]
  ) return null;

  const dateToTest = new Date();
  const startDate = new Date("09/30/2024"); // Sep 30 2024
  const endDate = new Date("10/10/2024");   // Oct 10 2024

  const isNowInRange = isWithinDateRange(dateToTest, startDate, endDate);

  if (isNowInRange) {
    return (
      <div className="banner-experience-survey">
        <p>
          Reminder: Data Analysis is now on Canvas!
          You can use the same course links you've always used and it will take you to Canvas.
        </p>
      </div>
    );
  }
  return null;
};

export default useDataAnalysisBroadcast;
