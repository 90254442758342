import IconPPT from "../../assets/app-badges/icon-ppt.svg";
import IconFWS from "../../assets/app-badges/icon-fws.svg";
import IconCU from "../../assets/app-badges/icon-cu.svg";
import IconMeetMyTeam from "../../assets/app-badges/icon-meet-my-team.svg";
import IconCareerHub from "../../assets/app-badges/icon-career-hub.svg";

import { EPP_MSG, SPP_MSG } from "./data";

/**
 * Need Help section icon data.
 */
export const helpIcons = [
  {
    icon: IconMeetMyTeam,
    name: "IconMeetMyTeam",
    link: "/my-team",
    target: "_self",
    title: "Meet My Team",
    disabledMsg: EPP_MSG,
  },
  {
    icon: IconPPT,
    name: "IconPPT",
    link: "/learning-center",
    target: "_self",
    title: "Peer Tutoring",
    disabledMsg: EPP_MSG,
  },
  {
    icon: IconCareerHub,
    name: "IconCareerHub",
    link: "/career-services",
    target: "_self",
    title: "Career Connect",
    disabledMsg: SPP_MSG,
  },
  {
    icon: IconFWS,
    name: "IconFWS",
    link: "/wellness-services",
    target: "_self",
    title: "Free Wellness Service",
    disabledMsg: SPP_MSG,
  },
  {
    icon: IconCU,
    name: "IconCU",
    link: "/help",
    target: "_self",
    title: "Ask Us a Question",
    disabledMsg: "",
  },
];
