import React from "react";
import PropTypes from "prop-types";

import { Header } from "@storybook/stories/Header/Header";
import { Resources } from "@storybook/stories/Resources/Resources";
import { MeetYourTeam } from "@storybook/stories/MeetYourTeam/MeetYourTeam";

import {
  AccessibilityServicesData,
  MeetYourTeamData,
} from "../../utils/helpers/accessibility-services";

import "./accessibilityservices.css";

export const AccessibilityServices = ({ user }) => {
  return (
    <article
      className="storybook-accessibility"
      data-testid="accessibilityContainer"
    >
      <Header
        user={user}
        onLogin={() => setUser(user)}
        onLogout={() => setUser(undefined)}
        activeLink="services"
        avatarsize="medium"
      />
      <main>
        <div className="container">
          {user && (
            <>
              <Resources
                title="Accessibility Services"
                subtitle={
                  <>
                    For information and assistance with registeration, please
                    email{" "}
                    <a href="mailto:accessibilityservices@calbright.org">
                      accessibilityservices@calbright.org
                    </a>
                    .
                  </>
                }
                resources={AccessibilityServicesData}
              />
              <MeetYourTeam teammembers={MeetYourTeamData} />
            </>
          )}
        </div>
      </main>
    </article>
  );
};

AccessibilityServices.propTypes = {
  user: PropTypes.shape({}),
};

export default AccessibilityServices;
