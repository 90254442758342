export default {
  apiCallsInProgress: 0,
  isAuthenticated: false,
  user: {},
  token: {},
  appointment: null,
  appointmentLinks: {},
  teamData: null,
  loading: true,
  error: null,
  avatars: null,
  avatar: null,
  kickoffScheduled: null,
  preReqStep: {}
};
