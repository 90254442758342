import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Header } from "@storybook/stories/Header/Header";
import { Modal } from "@storybook/stories/Modal/Modal";
import { Button } from "@storybook/stories/Button/Button";
import { Aside } from "@storybook/stories/Aside/Aside";
import { AvatarList } from "@storybook/stories/AvatarList/AvatarList";
import GreenCheck from "../../assets/profile/green-check.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "@storybook/utils/helpers.js";
import isStudentEnrolled from "../../utils/isStudentEnrolled";
import { updateAvatarUrl } from "../../api/avatarApi";

import "./profile.css";

export const Profile = ({
  user,
  updateUser,
  token,
  avatars,
  fetchAvatars,
  updateAvatar,
}) => {
  const [isEnableWithdrawForm, setIsEnableWithdrawForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const learnerStatus =
    user?.learnerStatus === undefined ? "" : user.learnerStatus;

  const handleOpenAvatarModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!avatars) fetchAvatars(token);
    // Find selectedAvatar by finding match based on avatar.icon
    if (avatars) {
      const currentAvatarIcon = updateAvatarUrl(user.profileImageUrl);
      const findSelectedtAvatar = avatars.filter(
        (avatar) => avatar.icon === currentAvatarIcon
      );
      setSelectedAvatar(findSelectedtAvatar[0]);
    }
  }, [avatars]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const updateSelectedAvatar = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const updateActiveAvatar = () => {
    if (user.profileImageUrl !== selectedAvatar.icon) {
      const updatedUser = {
        ...user,
        profileImageUrl: selectedAvatar.icon,
      };
      updateUser(updatedUser);
      updateAvatar(token, updatedUser.profileImageUrl);

      toast.success(
        <>
          <img
            className="green-check"
            src={GreenCheck}
            alt="green check icon"
          />
          You have successfully changed your Avatar!
        </>,
        {
          toastId: "avatar-change-success",
          role: "alert",
          position: "top-right",
          hideProgressBar: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        }
      );
    }

    toggleModal();
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  useEffect(() => {
    if (user?.withdrawDeadline) {
      const today = new Date();
      const withdrawDeadline = new Date(user.withdrawDeadline);
      today.setHours(23, 59, 59, 999);
      withdrawDeadline.setHours(23, 59, 59, 999);

      setIsEnableWithdrawForm(today <= withdrawDeadline);
    }
  }, [user]);

  return (
    <article className="storybook-profile" data-testid="profileContainer">
      <Header
        user={user}
        onLogin={() => setUser(user)}
        onLogout={() => setUser({})}
        activeLink="profile"
        avatarsize="medium"
      />

      <ToastContainer autoClose={3000} className="storybook-profile-toast" />
      {user && (
        <main>
          <div className="container">
            <div className="storybook-profile-content">
              <div>
                <section
                  className="storybook-profile-intro"
                  data-testid="profileIntro"
                >
                  <div className="page-title">
                    <h1>My Profile</h1>
                    <p>
                      View and manage your enrolled program, check for important
                      information and update personal details.
                    </p>
                  </div>
                </section>
                <section
                  className="storybook-profile-details white-container"
                  data-testid="profileDetails"
                >
                  <div className="storybook-profile-card">
                    <img
                      className="storybook-profile-img"
                      src={user.profileImageUrl}
                      alt={`Avatar of ${user.firstName + " " + user.lastName}`}
                    />
                    <h2 className="storybook-profile-name showInMobile">
                      {user.firstName + " " + user.lastName}
                    </h2>
                    <Button
                      buttonType="basic"
                      label="Change My Avatar"
                      size="medium"
                      onClick={handleOpenAvatarModal}
                    />
                  </div>
                  <div>
                    <p className="storybook-profile--cccid">
                      CCID: {user.cccId}
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Enrollment Date</span>
                      <span>{formatDate(user.enrollmentDate)}</span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>End of Term Date</span>
                      <span>{formatDate(user.currentTermEndDate)}</span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Drop Deadline</span>
                      <span className="red-color">
                        {formatDate(user?.dropDeadline)}
                      </span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Withdraw Deadline</span>
                      <span className="red-color">
                        {formatDate(user?.withdrawDeadline)}
                      </span>
                    </p>
                  </div>
                  <div className="storybook-profile--userstatus">
                    <h2 className="showInDesktop">
                      {user?.firstName + " " + user.lastName}
                    </h2>
                    <h3>Program</h3>
                    <p>{user.currentProgram}</p>
                    <h3>Enrollment Status</h3>
                    <p>
                      {!isStudentEnrolled(learnerStatus) &&
                        `Not Actively Enrolled`}
                      {learnerStatus.startsWith("Completed Program") &&
                        `Alumni`}
                      {learnerStatus.startsWith("Enrolled in Program") &&
                        `Provisionally Enrolled`}
                      {learnerStatus.startsWith("Started Program") &&
                        `Actively Enrolled`}
                    </p>
                    <h3>Address</h3>
                    <p>{user?.address}</p>
                    <h3>Email Address</h3>
                    <p>{user.email}</p>
                    <h3>Phone Number</h3>
                    <p>{formatPhoneNumber(user.phoneNumber)}</p>
                  </div>
                </section>
              </div>
              <Aside cornerType="all" title="Admissions and Records Services">
                <div className="storybook-profile-aside-block">
                  <p className="storybook-profile-aside--action">
                    Request Transcript
                  </p>
                  <p>
                    To receive your Calbright transcript, complete this{" "}
                    <a
                      href="https://forms.gle/7iN1qKdLtputuyaZ6"
                      target="_blank"
                    >
                      Transcript Request Form
                    </a>
                  </p>
                </div>
                {isEnableWithdrawForm && (
                  <div className="storybook-profile-aside-block">
                    <p className="storybook-profile-aside--action">
                      Withdrawing from Calbright
                    </p>
                    <p>
                      To withdraw from your program before your withdrawal{" "}
                      <b>deadline</b>, submit this{" "}
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScEuGrCLLLw3vINciFJytb2ia_hMv_sQolWslTnSsZII6Nc6g/formrestricted"
                        target="_blank"
                      >
                        Course Drop Request Form.
                      </a>
                      .
                    </p>
                  </div>
                )}
                <div className="storybook-profile-aside-block">
                  <p className="storybook-profile-aside--action">
                    Requesting an Enrollment Verification Letter
                  </p>
                  <p>
                    To receive your letter, complete this{" "}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLScUaf8vMMeGUxkYnZrf7D5385_X4pMX8Jj_dXx3XCTKDwsFlg/viewform"
                      target="_blank"
                    >
                      Enrollment Verification Form
                    </a>
                    .
                  </p>
                </div>
                <div className="storybook-profile-aside-block">
                  {user?.lms === "Canvas" ? (
                    <>
                      <p className="storybook-profile-aside--action">
                        Accessing your Completion Badges
                      </p>
                      <p>
                        To access your earned badges,{" "}
                        <a
                          href="https://calbright.badgr.com/auth/login"
                          target="_blank"
                        >
                          <span className="sr-only">Opens in new window </span>
                          log in here{" "}
                          <span className="sr-only">
                            to access your completion badges
                          </span>
                        </a>
                        .
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="storybook-profile-aside--action">
                        Accessing your Certificate of Competency
                      </p>
                      <p>
                        To receive all your Calbright credentials after you’ve
                        completed your program,{" "}
                        <a
                          href="https://www.credential.net/retrieve-credentials"
                          target="_blank"
                        >
                          <span className="sr-only">Opens in new window</span>
                          fill out this form
                        </a>
                        .
                      </p>
                    </>
                  )}
                </div>
                <div className="storybook-profile-aside-block">
                  <p>
                    To update your personal information, please contact our
                    Enrollment Services team at{" "}
                    <a href="mailto:success@calbright.org">
                      success@calbright.org
                    </a>
                  </p>
                </div>
              </Aside>
              {isModalOpen && (
                <Modal
                  openDialog={true}
                  showCloseIcon={true}
                  confirmText="Apply"
                  confirmClick={updateActiveAvatar}
                  cancelText="Cancel"
                  cancelClick={toggleModal}
                  size="large"
                >
                  <AvatarList
                    avatars={avatars}
                    triggerAvatarUpdate={updateSelectedAvatar}
                    currentAvatarId={selectedAvatar?.id}
                  />
                </Modal>
              )}
            </div>
          </div>
        </main>
      )}
    </article>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({}),
};

export default Profile;
