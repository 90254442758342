import React from "react";

import FemGreen from "../../assets/avatars/fem-green.svg";
import FemTan from "../../assets/avatars/fem-tan.svg";
import FemYellow from "../../assets/avatars/fem-yellow.svg";

import GentGreen from "../../assets/avatars/gent-green.svg";
import GentTan from "../../assets/avatars/gent-tan.svg";
import GentYellow from "../../assets/avatars/gent-yellow.svg";

import UserFilledIn from "../../assets/avatars/user-filled-in.svg";
import UserInverted from "../../assets/avatars/user-inverted.svg";
import UserOutline from "../../assets/avatars/user-outline.svg";

export const Avatars = [
  {
    id: 1,
    icon: FemGreen,
  },
  {
    id: 2,
    icon: FemTan,
  },
  {
    id: 3,
    icon: FemYellow,
  },
  {
    id: 4,
    icon: GentGreen,
  },
  {
    id: 5,
    icon: GentTan,
  },
  {
    id: 6,
    icon: GentYellow,
  },
  {
    id: 7,
    icon: UserFilledIn,
  },
  {
    id: 8,
    icon: UserInverted,
  },
  {
    id: 9,
    icon: UserOutline,
  },
];

/**
 * Data sample From /avatars api
 */

export const mockAvatarsAPI = {
  avatars: [
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_brown.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_pink.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_white.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_brown.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_pink.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_white.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_1.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_2.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_3.svg",
  ],
};

/**
 * Data response after avatar is updated successfully(POST)
 */

export const mockUpdatedAvatarAPI_Request = {
  avatar_link:
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/students/thumbnails/User_Avatar_Default.png",
};

export const mockUpdatedAvatarAPI_Response = {
  avatar_updated: true,
  message: "Avatar updated successfully for <user-email-address>",
};
