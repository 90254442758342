import React from "react";
import {
  learnerStatusLookup,
  studentProgramLookup,
} from "../utils/helpers/data";
import isWithinDateRange from "../utils/isWithinDateRange";

const useCyberSecurityBroadcast = (currentProgram, learnerStatus) => {
  if (currentProgram !== studentProgramLookup["CYBERSECURITY"]) {
    return null;
  }

  if (
    learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] ||
    learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"]
  ) {
  } else {
    return null;
  }

  const dateToTest    = new Date();
  const startDate     = new Date("9/05/2024");
  const canvLaunched  = new Date("9/18/2024");  // 09/18/2024 00:00:00 - Means including 09/17/2024 23:59:59
  const endDate       = new Date("9/24/2024");  // 09/24/2024 00:00:00 - Means including 09/23/2024 23:59:59
  const isNowInRange  = isWithinDateRange(dateToTest, startDate, endDate);

  if (!isNowInRange) return null;

  return (
    <div className="banner-experience-survey" data-testid="broadcastMsg">
      {dateToTest > canvLaunched ? (
        <p>
          Cybersecurity is now live on Canvas! You can use the same course
          links you've always used and it will now take you to Canvas.
          We have emailed you with details.
        </p>
      ) : (
        <p>
          Cybersecurity moves to Canvas in September. You'll still use the same
          student portal course link you've always used but it will take you to
          Canvas. We have emailed you with details.
        </p>
      )}
    </div>
  );
};

export default useCyberSecurityBroadcast;
