import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import back from '../assets/back.svg';
import close from '../assets/close.svg';

import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';

import './resources.css'

export const Resources = ({ title, subtitle, resources }) => {
  const [scrollTop, setScrollTop] = useState();
  const [isActive, setIsActive] = useState(null);
  const [resource, setResource] = useState(null);
  const [showIntro, setShowIntro] = useState(false);
  const leftColumn = useRef(null);
  const activeItem = useRef(null);
  const arrow = useRef(null);

  const handleShowIntro = () => {
    setShowIntro(!showIntro);
  };

  const handleToggle = (index) => {
    if (isActive === index) {
      leftColumn.current.scrollTop = 0;
      return setIsActive(null);
    }

    const selectedItem = resources.find(item => item.id === index);
    setResource(selectedItem);
    setIsActive(index);
  };

  const handleScroll = () => {
    getArrowPosition();
    checkArrowPosition();
  };

  const getArrowPosition = () => {
    if (leftColumn.current && activeItem.current) {
      const parentTop = leftColumn.current.getBoundingClientRect().top;
      const childTop = activeItem.current.getBoundingClientRect().top;
      setScrollTop(childTop - parentTop);
    }
  }

  const checkArrowPosition = () => {
    if (!arrow.current) return;
  
    if (scrollTop < -45 || scrollTop > 635) {
      arrow.current.classList.add('remove-arrow');
    } else {
      arrow.current.classList.remove('remove-arrow');
    }
  }

  useEffect(() => {
    if (arrow.current) {
      arrow.current.classList.remove('remove-arrow');
    }

    getArrowPosition();
  }, [isActive]);

  return (
    <div className="resources">
      <div className="page-title">
        <h1>{title}</h1>
        <p className={`storybook-intro ${showIntro ? 'show' : 'hide'}`}>
          {subtitle}
        </p>
      </div>
      <div className="storybook-intro-showmore" onClick={handleShowIntro}>
        {showIntro 
          ? <p>View Less <ArrowUpwardIcon /></p> 
          : <p>View More <ArrowDownwardIcon /></p> 
        }  
      </div>
      <div className="resources-wrap">
        <div className={`resources-left ${(isActive) ? "toggle" : ""}`}>
          <div className="resources-left--inner"
            onWheel={handleScroll}
            ref={leftColumn}
          >
            {resources && resources.map((item, index) => (
              <div
                className={`resources-row ${(isActive === item.id) ? "active" : ""}`}
                key={index}
                onClick={() => handleToggle(item.id)}
                data-testid={`resources-row-${item.id}`}
                ref={isActive === item.id ? activeItem : null}
              >
                <img src={item.image} alt={item.title} />
                <div>
                  <h3>{item.title}</h3>
                  {!isActive && (<p>{item.description}</p>)}
                </div>
              </div>
            ))}
          </div>
        </div>
        {(isActive && resource) && (
          <div className="resources-right" data-testid="resources-right">
            <span className="resources-arrow"
              ref={arrow}
              style={{top: `${scrollTop}px`}}
            />
            <div className="resources-right--inner">
              <button
                className="btn-close"
                onClick={() => handleToggle(null)}
              >
                <img src={close} alt="close" />
                <span>
                  <img src={back} alt="back" />
                  Back
                </span>
              </button>
              <h2>{resource.title}</h2>
              <p>{resource.description}</p>
              <div className="resources-content">
                {resource.content}
              </div>
              <img className="resource-bg" src={resource.image} alt={resource.title} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Resources.propTypes = {
  /**
   * Career title.
   */
  title: PropTypes.string,
  /**
   * Career subtitle.
   */
  subtitle: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.node
  ]),
  /**
   * Career Upcoming Workshops.
   */
  resources: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  }))
};