import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./appbadge.css";

export const AppBadge = ({
  id,
  title,
  icon,
  link,
  disabled,
  disabledMsg = "",
  active,
  target = "_blank",
  handleClick,
  linkType = "internal",
  children,
}) => {
  return link?.length > 0 ? (
    linkType === "external" ? (
      <a
        className="storybook-app-badge"
        href={link}
        disabled={disabled}
        active={active ? active.toString() : "false"}
        target={target}
        data-testid={`appBadge-${id}`}
      >
        {icon && <img src={icon} alt={`Icon ${title}`} />}
        <div className="storybook-app-badge--copy">
          <span>{title}</span>
          {children && <p>{children}</p>}
          {disabled && disabledMsg && (
            <span className="hover-tip">{disabledMsg}</span>
          )}
        </div>
      </a>
    ) : (
      <NavLink
        className="storybook-app-badge"
        to={link}
        disabled={disabled}
        active={active ? active.toString() : "false"}
        target={target}
        data-testid={`appBadge-${id}`}
      >
        {icon && <img src={icon} alt={`Icon ${title}`} />}
        <div className="storybook-app-badge--copy">
          <span>{title}</span>
          {children && <p>{children}</p>}
          {disabled && disabledMsg && (
            <span className="hover-tip">{disabledMsg}</span>
          )}
        </div>
      </NavLink>
    )
  ) : (
    <section
      className="storybook-app-badge"
      disabled={disabled}
      active={active ? active.toString() : "false"}
      data-testid={`appBadge-${id}`}
      onClick={(e) => {
        if (!disabled) {
          handleClick(id, title, e);
        }
      }}
    >
      {icon && <img src={icon} alt={`Icon ${title}`} />}
      <div className="storybook-app-badge--copy">
        <span>{title}</span>
        {children && <p>{children}</p>}
        {disabled && disabledMsg && (
          <span className="hover-tip">{disabledMsg}</span>
        )}
      </div>
    </section>
  );
};

AppBadge.propTypes = {
  /**
   * Badge title
   */
  title: PropTypes.string,
  /**
   * Badge svg icon
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Badge link
   */
  link: PropTypes.string,
  /**
   * Link type
   */
  linkType: PropTypes.oneOf(["internal", "external"]),
  /**
   * Check if app badge is enabled or not
   * depending on student enrollment status
   */
  disabled: PropTypes.bool,
  /**
   * Disabled message. default is an empty string
   */
  disabledMsg: PropTypes.string,
  /**
   * Check if app badge is active or not
   */
  active: PropTypes.bool,
  /**
   * Optional badge ID
   */
  id: PropTypes.any,
  /**
   * Optional click handler
   */
  handleClick: PropTypes.func,
  /**
   * Optional children
   */
  children: PropTypes.any,
  /**
   * Link target
   */
  target: PropTypes.string,
};

AppBadge.defaultProps = {
  id: "1",
  title: "App Badge",
  disabledMsg: "",
  active: true,
  target: "_blank",
  linkType: "internal",
};
