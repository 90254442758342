/**
 * Checks if today's date is within the startDate and endDate parameter range.
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns true if within range, false otherwise
 * @throws {Error} if startDate or endDate is not a valid Date object
 */
const isWithinDateRange = (dateToTest = new Date(), startDate, endDate) => {
  if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
    throw new Error("Invalid startDate");
  }

  if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
    throw new Error("Invalid endDate");
  }

  return dateToTest >= startDate && dateToTest <= endDate;
};

export default isWithinDateRange;
