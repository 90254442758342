
import IconStartedHover from '../stories/assets/task/task-started-hover.svg';
import IconInProgress from '../stories/assets/task/task-progress.svg';
import IconPassed from '../stories/assets/task/task-passed.svg';
import IconDone from '../stories/assets/task/task-done.svg';
import IconSuccess from '../stories/assets/task/task-success.svg';
import IconFirstAssignment from '../stories/assets/task/task-firstsaa.svg';
import IconMilestone1InProgress from '../stories/assets/task/task-diligent-lv1-inprogress.svg';
import IconMilestone1Complete from '../stories/assets/task/task-diligent-lv1-completed.svg';
/**
 * Progress status/icon values
 */
export const progress = [
  {
    name: "default",
    icon: '',               // Default icon drawn in CSS. No image required.
  },
  {
    name: "started",
    icon: IconStartedHover  // IconStarted is CSS not image required IconStartedHover used on mouseover
  },
  {
    name: "in-progress",
    icon: IconInProgress
  },
  {
    name: "passed",
    icon: IconPassed
  },
  {
    name: "done",
    icon: IconDone
  },
  {
    name: "success",
    icon: IconSuccess,
  },
  {
    name: "assignment-1",
    icon: IconFirstAssignment,
  },
  {
    name: "milestone-1-inprogress",
    icon: IconMilestone1InProgress,
  },
  {
    name: "milestone-1-complete",
    icon: IconMilestone1Complete,
  },
];