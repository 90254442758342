import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";

import { Header } from "@storybook/stories/Header/Header";
import { Card } from "@storybook/stories/Card/Card";
import { SkeletonMyTeams } from "../../components/Skeleton/SkeletonMyTeams";
import { ModalLogOut } from "../../components/ModalLogOut/ModalLogOut";

import "./myteam.css";
import { Button } from "@storybook/stories/Button/Button";

import PhoneIcon from "../../assets/icon-phone.svg";
import EmailIcon from "../../assets/icon-email.svg";
import SlackLogo from "../../assets/icon-slack.svg";
import InstructionalFaculty from "../../assets/myteam/instructional-faculty.svg";
import PeerTutors from "../../assets/myteam/peer-tutors.svg";
import AcademicSuccessCounselors from "../../assets/myteam/academic-success-counselors.svg";
import InstructorPreepp from "../../assets/myteam/instructor-preepp.png";
import CounselorPreepp from "../../assets/myteam/counselor-preepp.png";

import ArrowIcon from "../../assets/icon-arrow.svg";

import isStudentEnrolled from "./../../utils/isStudentEnrolled";
import axiosInstance from "../../api/axios";
import { handleError } from "../../api/apiUtils";
import { learnerStatusLookup } from "../../utils/helpers/data";

export const MyTeam = ({ user, token }) => {
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInstructors, setShowInstructors] = useState(false);
  const preEPPState = checkPreEPPState(user);
  let courseColors = [];

  function checkPreEPPState(user) {
    if (!user?.currentProgram) return false;
    const state = !isStudentEnrolled(user.learnerStatus);
    return state;
  }

  useEffect(() => {
    if (!user?.currentProgram) {
      return <ModalLogOut />;
    }
    if (preEPPState) {
      setLoading(false);
      setTeamData([]);
    } else {
      const fetchTeamData = async () => {
        try {
          const response = await axiosInstance.get(
            `/my_team?program_name=${user.currentProgram}`,
            { headers: { Authorization: `Bearer ${token?.idToken}` } }
          );
          setTeamData(response.data);

          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      };

      fetchTeamData();
    }
  }, []);

  const handleShowInstructors = useCallback(() => {
    setShowInstructors(!showInstructors);
  }, [showInstructors]);

  const handleClickOutsideInstructorList = useCallback(
    (event) => {
      const inside = event.target.closest(".storybook-myteam-instructors-list");

      if (showInstructors && inside == null) {
        handleShowInstructors();
      }
    },
    [showInstructors, handleShowInstructors]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideInstructorList);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideInstructorList
      );
    };
  }, [handleClickOutsideInstructorList]);

  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };

  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };

  const instructorCourseColors = (instructors) => {
    let globalAltColor = false;
    const courseColorsArray = instructors.map((instructor, index) => {
      if (index === 0) {
        return {
          altColor: false,
          course: instructor.course,
        };
      } else {
        if (
          instructor.course === instructors[index - 1].course &&
          !globalAltColor
        ) {
          return {
            altColor: false,
            course: instructor.course,
          };
        } else {
          globalAltColor = true;
          return {
            altColor: true,
            course: instructor.course,
          };
        }
      }
    });
    return courseColorsArray;
  };

  const handleAltCourseColor = (course, courseColors) => {
    let altColor = "false";
    courseColors.map((courseColor) => {
      if (courseColor.course === course) {
        altColor = courseColor.altColor;
      }
    });
    return altColor;
  };

  if (teamData && teamData?.instructors) {
    courseColors = instructorCourseColors(teamData.instructors);
  }

  return (
    <article className="storybook-myteam" data-testid="myteamContainer">
      <Header
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink="my-team"
        avatarsize="medium"
      />
      {loading ? (
        <SkeletonMyTeams />
      ) : (
        user &&
        teamData && (
          <main>
            <h1>My Team</h1>
            <div className="storybook-myteam-members">
              <section className="storybook-myteam-instructors">
                <h2>Meet Your Instructors</h2>
                <p>
                  Instructors can help you navigate your coursework and prepare
                  for your certification exam. Contact them when you need
                  academic help.
                </p>
                {preEPPState === true ||
                teamData.instructors?.error ||
                user.learnerStatus ===
                  learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"] ||
                (Object.hasOwn(teamData, "instructors") &&
                  teamData?.instructors.length < 1) ||
                !Object.hasOwn(teamData, "instructors") ? (
                  <div className="storybook-myteam-preepp white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title=""
                        children={
                          preEPPState === true ? (
                            <PreEppMessage
                              message="Your instructors are assigned when you enroll. Sign up for an onboarding session and enroll today."
                              signUpUrl="/"
                              btnLabel="Sign Up"
                            />
                          ) : (
                            <PreEppMessage
                              message="Looks like your Instructors have not been assigned. Reach out to us so we can help"
                              signUpUrl={"mailto:success@calbright.org"}
                              btnLabel="Contact Support"
                            />
                          )
                        }
                        imageSrc={InstructorPreepp}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                ) : (
                  <div className="storybook-myteam-instructors-list white-container">
                    <section
                      className={`storybook-myteam-instructors-list-item ${
                        showInstructors && "show"
                      }`}
                      data-testid="instructor-list-content"
                    >
                      {teamData.instructors?.map((instructor, index) => (
                        <Card
                          key={index}
                          title={instructor.name}
                          children={
                            <MyTeamMemberDetails
                              user={user}
                              applyAltColor={handleAltCourseColor(
                                instructor?.course,
                                courseColors
                              )}
                              {...instructor}
                            />
                          }
                          imageSrc={instructor.profile_image}
                        />
                      ))}
                    </section>
                    {teamData.instructors?.length > 2 && (
                      <div
                        className="storybook-myteam-instructors-show-more"
                        onClick={handleShowInstructors}
                      >
                        <img
                          src={ArrowIcon}
                          className={showInstructors ? "expand-rotate" : ""}
                        />
                      </div>
                    )}
                  </div>
                )}
              </section>
              <section className="storybook-myteam-counselors">
                <h2>Meet Your Success Counselor</h2>
                <p>
                  Success Counselors can help you with goal setting, time
                  management and career readiness. They’ll also connect you to
                  additional Calbright resources to support your journey.
                </p>
                {preEPPState === true ||
                teamData.counselor?.error ||
                user.learnerStatus ===
                  learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"] ||
                (Object.hasOwn(teamData, "counselor") &&
                  Object.keys(teamData?.counselor).length === 0) ||
                !Object.hasOwn(teamData, "counselor") ? (
                  <div className="storybook-myteam-preepp white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title=""
                        children={
                          preEPPState === true ? (
                            <PreEppMessage
                              message="Your Success Counselors are assigned when you enroll. Sign up for an onboarding session and enroll today."
                              signUpUrl="/"
                              btnLabel="Sign Up"
                            />
                          ) : (
                            <PreEppMessage
                              message="Looks like your Success Counselor has not been assigned. Reach out to us so we can help"
                              signUpUrl={"mailto:success@calbright.org"}
                              btnLabel="Contact Support"
                            />
                          )
                        }
                        imageSrc={CounselorPreepp}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                ) : (
                  <div className="storybook-myteam-counselors-list white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title={teamData.counselor?.name}
                        children={
                          <MyTeamMemberDetails
                            user={user}
                            memberType="Counselor"
                            {...teamData?.counselor}
                            applyAltColor={false}
                          />
                        }
                        imageSrc={teamData.counselor?.profile_image}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                )}
              </section>
            </div>
            <p className="storybook-myteam-copy">
              Our instructional faculty and support teams are all here with one
              goal — to help you acquire and master the skills and knowledge you
              need to earn a credential and advance your career. Whether you
              need help with academics, preparation for a new career or guidance
              navigating Calbright’s resources and offerings, we’re here for
              you!
            </p>

            <section className="storybook-myteam-instructional-faculty">
              <img src={InstructionalFaculty} alt="" />
              <div>
                <h2>Instructional Faculty</h2>
                <p>
                  Instructors at Calbright lead your program pathway, review
                  your assignments, organize industry expert speakers series and
                  more. They’re your go-to for academic help. You can also get
                  help from tutors in the{" "}
                  <NavLink to="/learning-center">
                    Tutoring and Learning Center
                  </NavLink>
                  .
                </p>
              </div>
            </section>
            <section className="storybook-myteam-peer-tutors">
              <div>
                <h2>Peer Tutors</h2>
                <p>
                  Tutors are available to help you throughout your learning
                  journey. First, they offer Getting Started workshops that
                  provide you with everything you need to know for a successful
                  start. As you progress through your program, they can help you
                  develop learning strategies, work with you through difficult
                  course assignments, and study with you for the certification
                  exam. Learn more about{" "}
                  <NavLink to="/learning-center">
                    Tutoring and Learning Center offerings
                  </NavLink>
                  .
                </p>
              </div>
              <img src={PeerTutors} alt="" />
            </section>
            <section className="storybook-myteam-academic-success-counselors">
              <img src={AcademicSuccessCounselors} alt="" />
              <div>
                <h2>Success Counselors</h2>
                <p>
                  Each Calbright student is assigned a Success Counselor.
                  Success Counselors will help you set goals, manage your time,
                  stay organized and prepare for your next career move. They can
                  help you navigate through life challenges to keep you
                  motivated, focused and on track.
                </p>
              </div>
            </section>
          </main>
        )
      )}
    </article>
  );
};

MyTeam.propTypes = {
  user: PropTypes.shape({}),
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      course: PropTypes.string,
      department_name: PropTypes.string,
      department_number: PropTypes.string,
      email: PropTypes.string,
      slack: PropTypes.string,
      calendlyU_link: PropTypes.string,
      phone: PropTypes.string,
      availability: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      profile_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  counselor: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    slack: PropTypes.string,
    calendly_link: PropTypes.string,
    phone: PropTypes.string,
    officeHours: PropTypes.string,
    profile_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

MyTeam.defaultProps = {};

/**
 * MyTeamMemberDetails component.
 * @returns team member data
 */
const MyTeamMemberDetails = ({
  memberType,
  course,
  role,
  department_name,
  department_number,
  email,
  slack,
  calendly_link,
  phone,
  availability,
  user,
  applyAltColor,
}) => {
  const properCaseDay = (day) => {
    return day
      .toLowerCase()
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  };
  const formattedOfficeHours = Array.isArray(availability)
    ? availability.map((day) => properCaseDay(day)).join(", ")
    : availability;

  const calendlyParams = calendly_link
    ? `${calendly_link}?full_name=${user.firstName} ${user.lastName}&email=${user.email}`
    : "";
  const altColor = applyAltColor;

  return (
    <div className="storybook-myteam-member">
      {memberType === "Counselor" ? (
        <>
          <h3 className="storybook-myteam-title">{role}</h3>
          <p className="storybook-myteam-desc">
            Check availability by clicking on schedule a meeting button.
          </p>
        </>
      ) : (
        <>
          <p
            className={`storybook-myteam-course-details ${
              altColor ? "alt-color" : ""
            }`}
          >
            {course}
          </p>
          <p className="storybook-myteam-office-hours">
            {formattedOfficeHours}
          </p>
        </>
      )}
      <Button
        buttonType="basic"
        size="small"
        label="Schedule a meeting"
        onClick={() => window.open(calendlyParams, "_blank")}
      />
      <div className="storybook-myteam-member-contact">
        <p className="storybook-myteam-slack">
          <a href={slack} target="_blank" rel="noreferrer">
            <img src={SlackLogo} className="slack-logo" alt="" /> Slack
          </a>
        </p>
        <p className="storybook-myteam-email">
          <a href={`mailto:${email}`}>
            <img src={EmailIcon} /> Email
          </a>
          <span>{email}</span>
        </p>
        {memberType === "Counselor" && (
          <p className="storybook-myteam-phone">
            <img src={PhoneIcon} /> {phone} (Text)
          </p>
        )}
      </div>
    </div>
  );
};

/**
 * PreEppMessage component
 * @returns generic notification
 */
const PreEppMessage = ({ message, signUpUrl, btnLabel }) => {
  const history = useHistory();
  const handleClick = () => {
    if (signUpUrl.includes("mailto")) {
      window.location.href = signUpUrl;
    } else {
      history.push(signUpUrl);
    }
  };
  return (
    <div className="storybook-myteam-member">
      <p>{message}</p>
      {signUpUrl && (
        <Button
          buttonType="basic"
          size="medium"
          label={btnLabel}
          onClick={() => handleClick(signUpUrl)}
        />
      )}
    </div>
  );
};

export default MyTeam;
