import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '../Accordion/Accordion';
import { Button } from '../Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';

import './wellness.css';

export const Wellness = ({
  title,
  subtitle,
  items,
  accordionItems = null,
  enrolled = false,
  cardTitle,
  cardContent,
  notEnrCardContent = '',
  btnLabel,
  btnSubmit,
  message,
  closeMessage
}) => {
  return (
    <div className="wellness">
      <div className="page-title">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <div className="wellness-row">
        {items && items.map((item, index) => (
          <div className="wellness-col" key={index} data-testid={`wellness-col-${index}`}>
            <img src={item.image} alt={item.title} />
            <div>
              <h3>{item.title}</h3>
              <p>{item.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="card">
        <h2>{cardTitle}</h2>
        {cardContent && <p>{parse(cardContent)}</p>}
        {!enrolled && 
          <p data-testid="card-content">
            {parse(notEnrCardContent)}
          </p>
        }
        <Button
          label={btnLabel}
          buttonType="default"
          disabled={!enrolled}
          onClick={btnSubmit}
        />
        {message && <p className="wellness-message">
          {parse(message)}
          <CloseIcon onClick={closeMessage} />
        </p>}
      </div>

      { accordionItems && <Accordion items={accordionItems}
        title="Frequently Asked Questions" />}
    </div>
  );
};

Wellness.propTypes = {
  /**
   * Wellness title.
   */
  title: PropTypes.string,
  /**
   * Wellness subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Card title.
   */
  cardTitle: PropTypes.string,
  /**
   * Card content.
   */
  cardContent: PropTypes.string,
  /**
   * Card Pre Enrolled content.
   */
  notEnrCardContent: PropTypes.string,
  /**
   * Card Button label.
   */
  btnLabel: PropTypes.string,
  /**
   * Set button submit action.
   */
  btnSubmit: PropTypes.func,
  /**
   * Wellness columns.
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  })),
  /**
   * Is student enrolled.
   */
  enrolled: PropTypes.bool,
  /**
   * Request message response.
   */
  message: PropTypes.string,
  /**
   * Hide message.
   */
  closeMessage: PropTypes.func,
};