import React from "react";
import PropTypes from "prop-types";

import { Header } from "@storybook/stories/Header/Header";
import { Resources as ResourcesBlock } from "@storybook/stories/Resources/Resources";
import { ResourcesData as resources } from "../../utils/helpers/resources";

export const Resources = ({ user }) => {
  return (
    <article className="page-resources">
      <Header
        user={user}
        onLogin={() => setUser({ name: "Jane Doe" })}
        onLogout={() => setUser(undefined)}
        activeLink="resources"
        avatarsize="medium"
      />
      <main>
        {user && (
          <section>
            <div className="container">
              <ResourcesBlock
                title="Resources for Students and Families"
                subtitle="Additional support for you and your family."
                resources={resources}
              />
            </div>
          </section>
        )}
      </main>
    </article>
  );
};

Resources.propTypes = {
  user: PropTypes.shape({}),
};

export default Resources;
// export default RequireAuth(Resources);
