export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const SAVE_CODE_SUCCESS = "SAVE_CODE_SUCCESS";

export const LOAD_TOKEN_SUCCESS = "LOAD_TOKEN_SUCCESS";
export const SAVE_TOKEN_SUCCESS = "SAVE_TOKEN_SUCCESS";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

export const LOAD_APPOINTMENT_SUCCESS = "LOAD_APPOINTMENT_SUCCESS";
export const SET_APPOINTMENT_SUCCESS = "SET_APPOINTMENT_SUCCESS";

export const LOAD_APPOINTMENTLINKS_SUCCESS = "LOAD_APPOINTMENTLINKS_SUCCESS";
export const SET_APPOINTMENTLINKS_SUCCESS = "SET_APPOINTMENTLINKS_SUCCESS";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

export const FETCH_TEAMDATA_REQUEST = "FETCH_TEAMDATA_REQUEST";
export const FETCH_TEAMDATA_SUCCESS = "FETCH_TEAMDATA_SUCCESS";
export const FETCH_TEAMDATA_FAILURE = "FETCH_TEAMDATA_FAILURE";

export const FETCH_AVATARS_SUCCESS = "FETCH_AVATARS_SUCCESS";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";

export const KICKOFF_SCHEDULED_SUCCESS = "KICKOFF_SCHEDULED_SUCCESS";

export const PRE_REQ_MOVE_STEP = "PRE_REQ_MOVE_STEP";
export const UPDATE_USER_PREREQ = "UPDATE_USER_PREREQ";
export const UPDATE_USER_PROGRAM = "UPDATE_USER_PROGRAM";

export const SHOW_LOADING = "SHOW_LOADING";
