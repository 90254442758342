import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import * as signoutActions from "../../redux/actions/signoutActions";

const SignOutPage = ({ signOut }) => {
  useEffect(() => {
    signOut();
  }, []);
  // remove localstorage items firstSAAViewed, schedul-kickoff, schedule-onboarding, previousUrl, userData
  localStorage.removeItem("schedule-kickoff");
  localStorage.removeItem("schedule-onboarding");
  localStorage.removeItem("previousUrl");
  localStorage.removeItem("userData");

  setTimeout(() => window.location.replace(process.env.LOGOUT_URI), 500);

  return <img src="https://accounts.google.com/logout" width="1" height="1" />;
};

SignOutPage.propTypes = {
  signOut: PropTypes.func.isRequired,
};

const mapDispathToProps = {
  signOut: signoutActions.signOut,
};

export default connect(null, mapDispathToProps)(SignOutPage);
