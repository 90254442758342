import * as types from "./actionTypes";
import * as userApi from "../../api/userApi";
import { handleError } from "../../api/apiUtils";
import { beginApiCall, apiCallError } from "./apiStatusActions.js";

export function setUserSuccess(user) {
  return { type: types.SET_USER_SUCCESS, user: user };
}

export function updateUserSuccess(user) {
  return { type: types.UPDATE_USER_SUCCESS, user: user };
}

export function kickoffScheduledSuccess(kickoffScheduled) {
  return { type: types.KICKOFF_SCHEDULED_SUCCESS, kickoffScheduled };
}

export function updateUserPreReqAction(payload) {
  return { type: types.UPDATE_USER_PREREQ, payload };
}

export function updateUserProgramAction(payload) {
  return { type: types.UPDATE_USER_PROGRAM, payload };
}

export function saveUser(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const user = await userApi.getUser(token);
      dispatch(setUserSuccess(user));
      return user;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function updateUser(user) {
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    try {
      dispatch(updateUserSuccess(user));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function setKickOffScheduled(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const kickoffScheduled = await userApi.setKickOffScheduled(token);
      dispatch(kickoffScheduledSuccess(kickoffScheduled));
      return kickoffScheduled;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

// export function updateUserPreReq(prereqStatus) {
//   console.log('prereqStatus', prereqStatus);
//   return function (dispatch) {
//     dispatch(beginApiCall());
//     try {
//       dispatch(updateUserPreReqSuccess(prereqStatus));
//       console.log('prereqStatus');
//     } catch (error) {
//       dispatch(apiCallError(error));
//       throw error;
//     }
//   };
// }
