import { combineReducers } from "redux";
import apiCallStatusReducer from "./apiStatusReducer";
import user from "./userReducer";
import token from "./tokenReducer";
import signOut from "./signOutReducer";
import { buttonChatReducer as buttonChatDisabled } from "./helpReducer";
import appointment, {
  appointmentLinksReducer as appointmentLinks,
} from "./appointmentReducer";
import teamData from "./myteamReducer";
import preReqStep from "./preReqStepReducer";
import avatar, { avatarsReducer as avatars } from "./avatarReducer";
import loading from "./loadingReducer";

const rootReducer = combineReducers({
  apiCallStatusReducer,
  loading,
  user,
  token,
  appointment,
  appointmentLinks,
  signOut,
  teamData,
  preReqStep,
  avatars,
  avatar,
  buttonChatDisabled,
});

export default rootReducer;
