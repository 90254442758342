import CatherineParker from "../../assets/myteam/catherine-parker.svg";
import ElliotFu from "../../assets/myteam/elliot-fu.svg";
import LailaJansen from "../../assets/myteam/laila-jansen.svg";
import EdisonCameron from "../../assets/myteam/edison-cameron.svg";
import PadminiJohannes from "../../assets/myteam/padmini-johannes.svg";
import BrittAllen from "../../assets/myteam/britt-allen.svg";
import JoachimNik from "../../assets/myteam/joachim-nik.svg";
import ShemilaJohnson from "../../assets/myteam/shemila-johnson.png";

export const menuData = [
  {
    id: "home",
    label: "Home",
    url: "/",
  },
  {
    id: "my-team",
    label: "My Team",
    url: "/my-team",
  },
  {
    id: "calendar",
    label: "Calendar",
    url: "/calendar",
  },
  {
    id: "services",
    label: "Services",
    dropdownItems: [
      {
        id: "wellness-services",
        label: "Wellness Services",
        url: "/services/",
      },
      {
        id: "career-services",
        label: "Career Services",
        url: "/career-services/",
      },
      {
        id: "accessibility-services",
        label: "Accessibility Services",
        url: "/services/accessibility",
      },
      {
        id: "academic-support",
        label: "Academic Support",
        url: "/services/academic-support",
      },
    ],
  },
  {
    id: "resources",
    label: "Resources",
    dropdownItems: [
      {
        id: "learning-resources",
        label: "Learning Resources",
        url: "/resources/learning",
      },
      {
        id: "counseling-resources",
        label: "Counseling Resources",
        url: "/resources/counseling",
      },
      {
        id: "support-resources",
        label: "Support Resources",
        url: "/resources/support",
      },
    ],
  },
];

export const EPP_MSG = "Will activate after Enrollment Agreement is signed";
export const SPP_MSG = "Will activate after first assignment is completed";

/**
 * Array of strings used to identify top level menu items.
 * NOTE: Function pulling this same data doesn't work in Storybook.
 */
export const menuIds = ["home", "my-team", "calendar", "services", "resources"];

/**
 * Prerequisite statuses.
 */
export const prereqStatusLookup = {
  APPROVED:         "approved",
  PENDING:          "pending",
  DENIED:           "denied",
  WAITING_SUPPORT:  "waiting_support"
}

/**
 * Learner Status used in determining steps in Dashboard task progress.
 * Some of the steps currently used are as follows. Map to the appropriate step in the Dashboard.
 * [ "enrollment-agreement", "schedule-kickoff", "kickoff", "onboarding", "enrolled", "graduated" ]
 */
export const learnerStatus = [
  "Expressed Interest",
  "App Started",
  "App Submitted",
  "Started Orientation",
  "Completed Orientation",
  "Completed CSEP",
  "Enrolled in Program Pathway",
  "Started Program Pathway",
  "Completed Program Pathway",
];

export const learnerStatusLookup = {
  EXPRESSED_INTEREST: "Expressed Interest",
  APP_STARTED: "App Started",
  APP_SUBMITTED: "App Submitted",
  READY_FOR_ONBOARDING: "Ready for Onboarding",
  STARTED_ORIENTATION: "Started Orientation",
  COMPLETED_ORIENTATION: "Completed Orientation",
  COMPLETED_CSEP: "Completed CSEP",
  ENROLLED_IN_PROGRAM_PATHWAY: "Enrolled in Program Pathway",
  ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE:
    "Enrolled in Program Pathway-KickoffComplete", // TODO: Replace Placeholder status (KickoffComplete) added for Dashboard functionality.
  STARTED_PROGRAM_PATHWAY: "Started Program Pathway",
  COMPLETED_PROGRAM_PATHWAY: "Completed Program Pathway",
};

/**
 * Enrolled Learner Statuses
 */
export const enrolledStatus = [
  "Enrolled in Program Pathway",
  "Enrolled in Program Pathway-KickoffComplete",
  "Started Program Pathway",
  "Completed Program Pathway",
];

export const studentProgramLookup = {
  T2T_CRM_ADMIN: "Customer Relationship Management",
  MEDICAL_CODING: "Medical Coding",
  IT_SUPPORT: "IT Support",
  CYBERSECURITY: "Cybersecurity",
  CAREER_READINESS: "Career Readiness",
  DATA_ANALYSIS: "Data Analysis",
  PROJECT_MANAGEMENT: "Project Management",
  HC_DEI: "HC DEI",
};

/**
 * List of instructors used for demo on the MyTeam page.
 */
export const Instructors = [
  {
    name: "Catherine Parker",
    course: "Workforce",
    courseNumber: "WF 500",
    department_name: "WF",
    department_number: "500",
    email: "catherine.parker@calbright.org",
    slack: "@catherineparker",
    calendlyUrl: "https://calendly.com/catherineparker",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: CatherineParker,
  },
  {
    name: "Elliot Fu",
    course: "Intro to IT",
    courseNumber: "IT 101",
    department_name: "IT",
    department_number: "101",
    email: "elliot.fu@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/elliotfu",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: ElliotFu,
  },
  {
    name: "Laila Jansen",
    course: "Intro to IT",
    department_name: "IT",
    department_number: "500",
    email: "laila.jansen@calbright.org",
    slack: "@laila.jansen",
    calendlyUrl: "https://calendly.com/laila.jansen",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: LailaJansen,
  },
  {
    name: "Edison Cameron",
    course: "Intro to IT",
    courseNumber: "IT 101",
    department_name: "IT",
    department_number: "101",
    email: "edison.carmeron@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/edison.carmeron",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: EdisonCameron,
  },
  {
    name: "Padmini Johannes",
    course: "Intro to IT",
    courseNumber: "IT 120",
    department_name: "IT",
    department_number: "120",
    email: "padmini.johannes@calbright.org",
    slack: "@padmini.johannes",
    calendlyUrl: "https://calendly.com/padmini.johannes",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: PadminiJohannes,
  },
  {
    name: "Britt Allen",
    course: "Workforce",
    department_name: "WF",
    department_number: "101",
    email: "britt.allen@calbright.org",
    slack: "@britt.allen",
    calendlyUrl: "https://calendly.com/britt.allen",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: BrittAllen,
  },
  {
    name: "Joachim Nik",
    course: "Workforce",
    courseNumber: "WF 251",
    department_name: "WF",
    department_number: "251",
    email: "joachim.nik@calbright.org",
    slack: "@joachim.nik",
    calendlyUrl: "https://calendly.com/joachim.nik",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    availability: ["tue", "thu", "sat"],
    profile_image: JoachimNik,
  },
  {
    availability: ["sat", "sun"],
    calendly_link: "https://calendly.com/daniel-juarez-calbright",
    course: "Intro. to Networks",
    department_name: "IT",
    department_number: "532",
    email: "daniel.juarez@calbright.org",
    name: "Dan Juarez",
    profile_image:
      "https://drive.google.com/thumbnail?id=1kN5VgFUC5VPYIVsojxWrEgdMP8BwnSjy",
    slack: "https://calbright-college.slack.com/team/U05SLHWD3PU",
  },
  {
    availability: ["mon", "wed"],
    calendly_link: "https://calendly.com/laurence_butler",
    course: "Intro. to Networks",
    department_name: "IT",
    department_number: "532",
    email: "laurence.butler@calbright.org",
    name: "Laurence Butler",
    profile_image:
      "https://drive.google.com/thumbnail?id=11UBid6jQMfIZpkj61ifv0oUvJCR7x1og",
    slack: "https://calbright-college.slack.com/team/U05S0CNDL2K",
  },
];

/**
 * Counselor information used for demo on the MyTeam page.
 */
export const Counselor = {
  calendly_link:
    "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting-15min",
  email: "shemila@calbright.org",
  name: "Shemila Johnson",
  profile_image: ShemilaJohnson,
  role: "Academic Success Counselor",
  slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
  text: "(510) 474-0551",
};

// Sample Data from API
export const RealData = {
  counselor: {
    calendly_link:
      "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting-15min",
    email: "shemila@calbright.org",
    name: "Shemila Johnson",
    profile_image:
      "https://drive.google.com/file/d/1H22nIq24aSPuNKmJnrWdOxWTFuZ8Tgyp/view?usp=sharing",
    role: "Academic Success Counselor",
    slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
    text: "(510) 474-0551",
  },
  instructor: [
    {
      availability: ["sat", "sun"],
      calendly_link: "https://calendly.com/daniel-juarez-calbright",
      course: "Introduction to Networks",
      department_name: "IT",
      department_number: "532",
      email: "daniel.juarez@calbright.org",
      name: "Dan Juarez",
      profile_image:
        "https://drive.google.com/uc?id=1kN5VgFUC5VPYIVsojxWrEgdMP8BwnSjy",
      slack: "https://calbright-college.slack.com/team/U05SLHWD3PU",
    },
    {
      availability: ["mon", "wed"],
      calendly_link: "https://calendly.com/laurence_butler",
      course: "Introduction to Networks",
      department_name: "IT",
      department_number: "532",
      email: "laurence.butler@calbright.org",
      name: "Laurence Butler",
      profile_image:
        "https://drive.google.com/uc?id=11UBid6jQMfIZpkj61ifv0oUvJCR7x1og",
      slack: "https://calbright-college.slack.com/team/U05S0CNDL2K",
    },
  ],
};

/**
 * My Teams for "IT Support" program based on API data.
 */
export const mockTeamData = {
  counselor: {
    calendly_link:
      "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting",
    email: "shemila@calbright.org",
    name: "Shemila Johnson",
    phone: "(510) 474-0551",
    profile_image:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/3b500c08-1750-4053-a8e2-249ec4ecec79.png",
    role: "Success Counselor",
    slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
  },
  instructors: [
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/mstewart-itsupport",
      course: "Technology Support (A+)",
      department_name: null,
      department_number: null,
      email: "michael@calbright.org",
      name: "Michael Stewart",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/050b9120-8729-4f29-b4e0-94db3d7af581.png",
      slack: "https://calbright-students.slack.com/team/U0215R9BGSW",
    },
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/odellcalbright/office-hours",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "ashley@calbright.org",
      name: "Ashley Odell",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/0d4738dd-8240-4cb5-a1d2-1abcce68bb2e.png",
      slack: "https://calbright-students.slack.com/team/U020R4LS97Z",
    },
    {
      availability: ["mon", "tue", "thu"],
      calendly_link: "https://calendly.com/michael-dudas",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "michael.dudas@calbright.org",
      name: "Michael Dudas",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/c3710549-405e-43a2-bf23-85c8567b10dc.png",
      slack: "https://calbright-students.slack.com/team/U0484DPGZ3J",
    },
    {
      availability: ["wed", "fri", "sun"],
      calendly_link: "https://calendly.com/cornelius-masonii",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "cornelius.masonii@calbright.org",
      name: "Cornelius Mason II",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/53cd5eb1-3493-4de9-822d-021f6c08bafb.png",
      slack: "https://calbright-students.slack.com/team/U047ENLB1C2",
    },
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/bringgenberg",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "bringgenberg@calbright.org",
      name: "Benjamin Ringgenberg",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/df6f03b8-2f65-4ef7-b0cb-86bad3719d48.png",
      slack: "https://calbright-students.slack.com/archives/D052JH2KTF0",
    },
    {
      availability: ["mon", "tue", "wed", "thu"],
      calendly_link: "https://calendly.com/mfranklin-calbright",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "michael.franklin@calbright.org",
      name: "Michael Franklin",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/164b59e4-b46a-4afb-b8cd-fc028ff73da3.png",
      slack: "https://calbright-students.slack.com/team/U05V3047G13",
    },
    {
      availability: ["sat", "mon", "wed"],
      calendly_link: "https://calendly.com/ola-moore/",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "oladele.moore@calbright.org",
      name: "Oladele Moore",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/3355ae6c-6f35-40f3-afb8-573cdc738729.png",
      slack: "https://calbright-students.slack.com/team/U04JTL9S8QL",
    },
    {
      availability: ["mon", "tue", "wed", "thu", "fri"],
      calendly_link: "https://calendly.com/lauren-van-talia",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "lauren-van.talia@calbright.org",
      name: "Lauren VanTalia",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/b0468087-4336-41bd-92dc-a27f61f44c66.png",
      slack: "https://calbright-students.slack.com/team/U06L126J8DC",
    },
    {
      availability: ["sun", "mon", "thu", "fri"],
      calendly_link: "https://calendly.com/tasha-johnson-office-hours/",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "tasha.johnson@calbright.org",
      name: "Tasha Johnson",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/aa7cd6c6-91dd-416c-a71c-a2c63c3666e5.png",
      slack: "https://calbright-students.slack.com/team/U0694TKTH38",
    },
    {
      availability: ["sat", "sun", "tue", "wed"],
      calendly_link: "https://calendly.com/luisjr-munguiarivera",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "luisjr.munguiarivera@calbright.org",
      name: "Luis Munguia Rivera",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/8cea3c70-c7d0-4f92-a357-3eb60d8a2641.png",
      slack: "https://calbright-students.slack.com/team/U05V33NCLA1",
    },
  ],
};

export const mockKickoffScheduled_Response = {
  message:
    "Kickoff scheduled updated successfully for <mock.user@calbright.org>",
  user_updated: true,
};
