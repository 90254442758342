import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tokenReducer(state = initialState.token, action) {
  switch (action.type) {
    case types.SAVE_CODE_SUCCESS:
      return {
        ...state,
        code: action.code,
      };
    case types.LOAD_TOKEN_SUCCESS:
      return action.token;
    case types.SAVE_TOKEN_SUCCESS || types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
}
